<template>
  <vue-good-table
    :ref="tableReference"
    :total-rows="totalRecords"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="filteredAlerts"
    style-class="vgt-table alerts-table"
    :max-height="maxHeight"
    :fixed-header="true"
    :group-options="{
      enabled: groupModeEnabled,
      headerPosition: 'bottom'
    }"
    :search-options="{
      enabled: enabledSearch,
      externalQuery: search
    }"
    :sort-options="{
      enabled: true
    }"
    @on-sort-change="sortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-search="onSearchTermChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span
        v-if="props.column.label == $t('time')"
        class="td-date"
      >
        <b>{{ moment(props.row.time).format('ddd') }}</b>
        <span>{{ moment(props.row.time).format('MMM DD') }}</span>
        <b>{{ moment(props.row.time).format('hh:mm') }}</b>
        <span>{{ moment(props.row.time).format('a') }}</span>
      </span>
      <div
        v-else-if="props.column.label == $t('alert')"
        class="td-alert"
      >
        <general-suicidal-alert
          v-if="props.row.template === 1"
          :alert="props.row"
          @acknowledge="acknowledge"
        />
        <general-suicidal-alert-multiple-questions
          v-else-if="props.row.template === 2"
          :alert="props.row"
          @acknowledge="acknowledge"
        />
      </div>
      <span
        v-if="props.column.label == $t('clinician_s')"
        class="td-clinician"
      >
        <div
          v-for="(clinician, index) in props.row.clinicians"
          :key="index"
        >
          {{ clinician }}
        </div>
      </span>
    </template>
    <div
      v-show="isLoading"
      slot="loadingContent"
    >
      <LoadingSpinner />
    </div>
    <div
      v-show="!isLoading"
      slot="emptystate"
    >
      <span
        v-if="!totalRecords && alertSelectedText != 'all'"
        class="no-alerts-message"
      >
        {{ $t('thereAreNo') }} <b>{{ $t(alertSelectedText).toLowerCase() }}</b> {{ $t('alerts') }}. <a
          href="javascript:void(0)"
          @click="$emit('changeAckSelector')"
        >{{ $t('viewAllAlerts') }}</a>.
      </span>
      <span v-else>
        {{ $t('thereAreNo') }} {{ $t('alerts') }}.
      </span>
    </div>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import generalSuicidalAlert from '@/components/common/measureAlertTemplates/generalSuicidalAlert'
import { ClinicianAlertTable } from '@/mixins/TableColumnsHelper'
import moment from 'moment'
import { USER_TYPE_SUPERVISOR } from '@/data/permissions'
import GeneralSuicidalAlertMultipleQuestions from '../common/measureAlertTemplates/generalSuicideAlertMultipleQuestions'
import { mapGetters, mapMutations } from 'vuex'
import { HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT } from '@/store/modules/applicationState/constants'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { GET_CLINICIAN_ALERTS } from '@/store/modules/clinicians/constants.js'
import LoadingSpinner from '@/components/common/LoadingSpinner'
const IE_RECORDS_LIMIT = 200

export default {
  name: 'ClinicianAlertTable',
  components: {
    GeneralSuicidalAlertMultipleQuestions,
    LoadingSpinner,
    VueGoodTable,
    generalSuicidalAlert
  },
  mixins: [ClinicianAlertTable, RemoteTable],
  props: ['search'],
  data () {
    return {
      submitState: false,
      hidePagination: true,
      tableMaxHeight: 135,
      tableReference: 'ClinicianAlertTable',
      action: GET_CLINICIAN_ALERTS,
      defaultSortOrder: 'desc',
      defaultSortField: 'time',
      userTypeSupervisor: USER_TYPE_SUPERVISOR
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser',
      alerts: 'getAlerts',
      uiSettings: 'getUiSettings'
    }),
    filterValue () {
      return this.uiSettings.alertsStatusSelector ? this.uiSettings.alertsStatusSelector.selected : 'active'
    },
    filteredAlerts () {
      if (this.filterValue === 'active') {
        return this.alerts.filter(a => !a.acknowledged_data.acknowledged)
      }
      return this.groupModeEnabled
        ? [{
            mode: 'span',
            html: true,
            label: this.$t('limitedRecordsShown', { total: this.totalRecords, shown: IE_RECORDS_LIMIT }),
            children: this.alerts
          }]
        : this.alerts
    },
    shouldGroup () {
      return this.filterValue !== 'active' && this.currentBrowser.isExplorer
    },
    groupModeEnabled () {
      return this.shouldGroup && this.totalRecords && parseInt(this.totalRecords) > IE_RECORDS_LIMIT
    },
    alertSelectedText () {
      try {
        return this.uiSettings.alertsStatusSelector.selected
      } catch (error) {
        return ''
      }
    },
    enabledSearch () {
      return typeof this.search !== 'undefined'
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    ...mapMutations({
      hideContactInformation: HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT
    }),
    moment: (date) => {
      const dateUtc = moment.utc(date).toDate()
      return moment(dateUtc).local()
    },
    sortChange (params) {
      this.onSortChange(params)
      this.$emit('sortChange')
    },
    acknowledge (alert) {
      if (this.submitState) {
        return false
      }

      this.submitState = true
      const loader = this.$loading.show()
      this.$store.dispatch('ACKNOWLEDGE_ALERT', alert).then(() => {
        this.$store.dispatch('GET_CLINICIAN_ALERTS_COUNT')
        this.hideContactInformation({ alertId: alert.id })
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        this.submitState = false
        loader.hide()
      })
    },
    beforeLoadItems () {
      if (this.shouldGroup) {
        this.serverParams.perPage = IE_RECORDS_LIMIT
        this.serverParams.paginate = true
      } else {
        this.serverParams.paginate = false
      }
    }
  }
}
</script>
