<template>
  <div class="alert-list">
    <ClinicianLayoutNav
      ref="clinicianLayoutNav"
      :show-patient-selector="true"
      :show-alerts-selector="true"
      :tabs="tabs"
      :show-search="true"
      :placeholder="$t('searchAlerts')"
      @searching="(s) => searchQuery = s"
      @filtersChanged="refreshList"
    />
    <clinician-alert-table
      ref="clinicianAlertTable"
      :search="searchQuery"
      @changeAckSelector="viewAllAlerts"
      @sortChange="setContactInfoDetail(null)"
    />

    <detail-view
      v-if="conctactInfoDetail"
      :show-header="false"
      @close="setContactInfoDetail(null)"
    >
      <template slot="content">
        <patient-detail-contact
          :respondent="conctactInfoDetail"
          @close="setContactInfoDetail(null)"
        />
      </template>
    </detail-view>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClinicianAlertTable from '@/components/clinician/ClinicianAlertTable'
import PatientDetailContact from '@/components/clinician/patients/PatientDetailContact'
import DetailView from '@/components/common/DetailView'
import ClinicianLayoutNav from '@/components/common/ClinicianLayoutNav'
import { CONTACT_INFO_DETAIL } from '@/store/modules/applicationState/constants'

export default {
  name: 'ClinicianAlertList',
  components: {
    ClinicianAlertTable,
    DetailView,
    ClinicianLayoutNav,
    PatientDetailContact
  },
  data () {
    return {
      searchQuery: '',
      tabs: [{
        label: 'Alerts',
        href: 'ClinicianAlertList'
      }]
    }
  },
  computed: {
    ...mapGetters({
      conctactInfoDetail: 'getConctactInfoDetail',
      uiSettings: 'getUiSettings'
    })
  },
  created () {
    // Per OWL-7450 always set to active on load
    this.$store.dispatch('UPDATE_UI_SETTING_VALUE', { settingKey: 'alertsStatusSelector', settingValue: { selected: 'active' } })
  },
  destroyed () {
    this.setContactInfoDetail(null)
  },
  methods: {
    ...mapMutations({
      setContactInfoDetail: CONTACT_INFO_DETAIL
    }),
    refreshList () {
      if (this.$refs.clinicianAlertTable) {
        this.setContactInfoDetail(null)
        this.$refs.clinicianAlertTable.loadItems()
      }
    },
    viewAllAlerts () {
      this.$store.dispatch('UPDATE_UI_SETTING_VALUE', { settingKey: 'alertsStatusSelector', settingValue: { selected: 'all' } })
      this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings).then(() => {})
      this.$refs.clinicianLayoutNav.clearSearch()
      this.refreshList()
    }
  }
}
</script>
